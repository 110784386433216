import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Logo from '../svg/logo.svg';

const Header = ({ siteTitle, lang }) => {
  const linkPrefix = lang ? `/${lang}`: '';
  
  return (
    <header>
      <div className="container my-4 ml-4 md:mx-auto">
        <div className="flex items-center justify-between">
          <div>
            <Link
              to={`${linkPrefix}/`}
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <Logo
                height={60}
                alt="Shift Power for Peace"
              />
            </Link>
          </div>
          <div className="text-brand-orange font-bold text-lg">
            <Link
              to={`${linkPrefix}/publications`}
              className="mr-8 hover:border-b-2 hover:border-brand-orange"
              activeClassName="border-b-2 border-brand-orange"
            >
              Publications
            </Link>
            <Link
              to={`${linkPrefix}/contact`}
              className="mr-8 hover:border-b-2 hover:border-brand-orange"
              activeClassName="border-b-2 border-brand-orange"
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
